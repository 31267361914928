import React, { Component, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';
import { format, set } from 'date-fns';

import CalendarComp from 'components/CalendarComp/CalendarComp';
import ToastComp from 'components/ToastComp/ToastComp';
import AddTask from './components/AddTask';
import EditTask from './components/EditTask';
import HeaderPageComp from 'components/HeaderPageComp/HeaderPageComp';
import ProgramComp from 'components/ProgramComp/ProgramComp';
import TabNoteComp from 'components/TabNoteComp/TabNoteComp';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import style from "assets/css/style.css"
import ListNoteTab from 'components/TabNoteComp/ListNoteTab';

const TabDetails = (props) => {

    let { tabId } = useParams();
    const todayDate = format(new Date(), "yyyy-MM-dd")

    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(todayDate);
    const [name, setName] = useState('');
    const [taskList, setTaskList] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('');
    const [editableId, setEditableId] = useState('');
    const [dataForUpdate, setDataForUpdate] = useState('');
    const [validateEdit, setValidateEdit] = useState(false);
    const [priorityTypes, setPriorityTypes] = useState('');

    const handleEditClick = (event, task) => {
        event.preventDefault();
        setEditableId(task.id);
        setLoading(true);
    }

    useEffect(() => {
        setDisplayDataTable()
    },[priorityTypes])

    const getDependenciesData = async () => {

        const res = await axios.get(`/api/tabs/${tabId}`);

        if(res.data.data) {
            setName(res.data.data.name)
        }

        const resPriorityTypes = await axios.get('/api/priority-type')
       
        if(resPriorityTypes.data.data.length > 0) {
           setPriorityTypes (resPriorityTypes.data.data)
        }

    }

    const refreshData = async () => {
        setLoading(true);
    }

    const deleteTask = async (e, taskId) => {
        
        const idClicked = e.currentTarget; 
        idClicked.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';

        const deleteTask = await axios.delete(`/api/tasks/${taskId}`);
        if(deleteTask) {
            setLoading(true);
        } else {
            console.log(deleteTask.data.message);
        }
    }

    const updateTask = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }
        setValidateEdit(true);

        const res = await axios.put(`/api/tasks/${editableId}`, dataForUpdate);

        if(res.data){
            setEditableId('');
            setLoading(true);
        }
    }

    const counterEstimate = (data) => {

        var totalEsimate = 0;
         
        data.map( item => {
            let totalTime = totalEsimate + parseFloat(item.estimate_time);
            totalEsimate = totalTime;
        })

        return parseFloat(totalEsimate).toFixed(2);
    }

    const counterAchieved = (data) => {

        var totalAchieved = 0;
         
        data.map( item => {
            if(item.time_achieved == null){
                item.time_achieved = 0;
            }
            let totalTime = totalAchieved + parseFloat(item.time_achieved);
            totalAchieved = totalTime;
        })

        return parseFloat(totalAchieved).toFixed(2);
    }

    const handleSaveStatus = async (e, itemId) => {
        const res = await axios.get(`/api/tasks/${itemId}`);

        let dataForUpdateTask = '';

        if(res) {
            dataForUpdateTask = {
                'project': res.data.data.project,
                'task': res.data.data.task,
                'emergency': res.data.data.emergency,
                'priority': res.data.data.priority,
                'estimate_time': res.data.data.estimate_time,
                'time_achieved': res.data.data.time_achieved ? res.data.data.time_achieved: '',
                'observation': res.data.data.observation ? res.data.data.observation: '',
                'day_date': res.data.data.day_date,
                'completed': e.target.checked,
                'task_type_id': res.data.data.task_type_id,
                'tab_id': tabId,
            }

            const res2 = await axios.put(`/api/tasks/${itemId}`, dataForUpdateTask);

            e.nativeEvent.path[2].attributes.class.value = ""

            if(e.target.checked) {
                e.nativeEvent.path[2].attributes.class.value = "customCompleted"
            }

            setLoading(true)
        }
        
    }

    const displayTypeValue = (value) => {
        let displayValue = '';
        if (priorityTypes.length > 0) {
            displayValue = priorityTypes.filter((itemp) => {return itemp.id == value}).map((item) => {return item.name});
        }
        return displayValue;
    }

    //Data tables
    const [displayDataHead, setDisplayDataHead] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [displayDataGeneral, setDisplayDataGeneral] = useState([]);
    const [displayDataSuplimentar, setDisplayDataSuplimentar] = useState([]);
    const [displayDataSuplimentarTitle, setDisplayDataSuplimentarTitle] = useState([]);
    const [displayDataNeprevazut, setDisplayDataNeprevazut] = useState([]);
    const [displayDataNeprevazutTitle, setDisplayDataNeprevazutTitle] = useState([]);


    
    const setDisplayDataTable = async () => {

        const resTasks = await axios.get(`/api/tasks/tab/${tabId}/${date}`)
        .then((data) => {
            setDisplayDataHead([]);
            if(data.data.data.length == 0) {
                setDisplayDataHead(<tr className="h3"><td colSpan={12}>Adauga task</td></tr>);
            }
            setDisplayDataSuplimentarTitle([]);
            setDisplayDataNeprevazutTitle([]);

            setDisplayDataGeneral(data.data.data.filter( (filterData) => { return filterData.task_type_id == 1}).map((item) => {
                setDisplayDataHead(
                    <tr className='customGeneral thead-green'>
                        <th>Finalizat</th>
                        <th>Proiect</th>
                        <th>Task</th>
                        <th>Urgenta</th>
                        <th>Importanta</th>
                        <th>T.E. ({counterEstimate(data.data.data)})</th>
                        <th>T.R. ({counterAchieved(data.data.data)})</th>
                        <th>Observatii</th>
                        <th>Actiuni</th>
                    </tr>
                );
                if(editableId === item.id){
                    return(
                        <EditTask 
                        key={item.id}
                        task_id={item.id} 
                        tab_id={tabId}
                        validated={setValidateEdit}
                        cancelEdit={setEditableId}
                        dataForUpdate={setDataForUpdate}
                        refreshData={refreshData}
                        updateShowToast={setShowToast}
                        updateMessageToast={setMessageToast}
                        updateTypeToast={setTypeToast}
                        />
                    )
                } else {
                    return(
                        <tr key={item.id} className={item.completed ? 'customCompleted': ''}>
                            <td>
                                <input
                                    className="form-check-input"
                                    name="completed"
                                    type="checkbox"
                                    defaultChecked={item.completed}
                                    onClick={(e) => handleSaveStatus(e, item.id)} 
                                />
                            </td>
                            <td><b>{item.project}</b></td>
                            <td><b>{item.task}</b></td>
                            <td>{displayTypeValue(item.emergency)}</td>
                            <td>{displayTypeValue(item.priority)}</td>
                            <td>{item.estimate_time}</td>
                            <td>{item.time_achieved ? item.time_achieved: ''}</td>
                            <td>{item.observation}</td>
                            <td>
                                <button onClick={(event) => handleEditClick(event, item)} className='btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
      <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
    </svg>
                                </button>
                                <button type='button' className='btn' onClick={ (e) => deleteTask(e, item.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" fill="currentColor"/>
        </svg>
                                </button>
                            </td>
                        </tr>
                    )
                }            
            })
            )

            setDisplayDataSuplimentar(data.data.data.filter( (filterData) => { return filterData.task_type_id == 2}).map((item) => {
                setDisplayDataSuplimentarTitle(<tr className='customSuplimentar'><td colSpan={9}><b>Suplimentar</b></td></tr>);
                if(editableId === item.id){
                    return(
                        <EditTask 
                        key={item.id}
                        task_id={item.id} 
                        tab_id={tabId}
                        validated={setValidateEdit}
                        cancelEdit={setEditableId}
                        dataForUpdate={setDataForUpdate}
                        refreshData={refreshData}
                        updateShowToast={setShowToast}
                        updateMessageToast={setMessageToast}
                        updateTypeToast={setTypeToast}
                        />
                    )
                } else {
                    return(
                        <tr key={item.id} className={item.completed ? 'customCompleted': ''}>
                            <td>
                                <input
                                    className="form-check-input"
                                    name="completed"
                                    type="checkbox"
                                    defaultChecked={item.completed}
                                    onClick={(e) => handleSaveStatus(e, item.id)} 
                                />
                            </td>
                            <td><b>{item.project}</b></td>
                            <td><b>{item.task}</b></td>
                            <td>{displayTypeValue(item.emergency)}</td>
                            <td>{displayTypeValue(item.priority)}</td>
                            <td>{item.estimate_time}</td>
                            <td>{item.time_achieved ? item.time_achieved: ''}</td>
                            <td>{item.observation}</td>
                            <td>
                                <button onClick={(event) => handleEditClick(event, item)} className='btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
      <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
    </svg>
                                </button>
                                <button type='button' className='btn' onClick={ (e) => deleteTask(e, item.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" fill="currentColor"/>
        </svg>
                                </button>
                            </td>
                        </tr>
                    )
                }            
            })
            )

            setDisplayDataNeprevazut(data.data.data.filter( (filterData) => { return filterData.task_type_id == 3}).map((item) => {
                setDisplayDataNeprevazutTitle(<tr className='customNeprevazut'><td colSpan={9}><b>Neprevazut</b></td></tr>);
                if(editableId === item.id){
                    return(
                        <EditTask 
                        key={item.id}
                        task_id={item.id} 
                        tab_id={tabId}
                        validated={setValidateEdit}
                        cancelEdit={setEditableId}
                        dataForUpdate={setDataForUpdate}
                        refreshData={refreshData}
                        updateShowToast={setShowToast}
                        updateMessageToast={setMessageToast}
                        updateTypeToast={setTypeToast}
                        />
                    )
                } else {
                    return(
                        <tr key={item.id} className={item.completed ? 'customCompleted': ''}>
                            <td>
                                <input
                                    className="form-check-input"
                                    name="completed"
                                    type="checkbox"
                                    defaultChecked={item.completed}
                                    onClick={(e) => handleSaveStatus(e, item.id)} 
                                />
                            </td>
                            <td><b>{item.project}</b></td>
                            <td><b>{item.task}</b></td>
                            <td>{displayTypeValue(item.emergency)}</td>
                            <td>{displayTypeValue(item.priority)}</td>
                            <td>{item.estimate_time}</td>
                            <td>{item.time_achieved ? item.time_achieved: ''}</td>
                            <td>{item.observation}</td>
                            <td>
                                <button onClick={(event) => handleEditClick(event, item)} className='btn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
      <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
    </svg>
                                </button>
                                <button type='button' className='btn' onClick={ (e) => deleteTask(e, item.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" fill="currentColor"/>
        </svg>
                                </button>
                            </td>
                        </tr>
                    )
                }            
            })
            )
        })

    }


    if(loading) {
        getDependenciesData();
        setDisplayDataTable();
        setLoading(false);
    }

    return(
        <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="primary"
                >
                  <MDTypography variant="h3" color="white">
                    <HeaderPageComp 
                            left={
                                <Link to={`/`} className='btn'> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fillRule="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                    </svg> 
                                </Link>
                            } 
                            center={
                                <div className='d-flex'>
                                    <span className='tabTitle-custom px-3'> {name} </span>
                                    <ProgramComp programTabId={tabId} programTabDate={date}/>
                                </div>
                            }
                            right={
                                <CalendarComp setSelectedDate={setDate} taskDate={date} setRefreshData={refreshData}/>
                            }
                        />
                    </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                <div className='d-flex justify-content-between'>
                        <div className='custom-TableRow col-10 p-2 px-2'>
                            <form onSubmit={updateTask} id="editTask">
                                <table className='table table-hover'>
                                    <thead>
                                        {displayDataHead}
                                    </thead>

                                    <tbody>
                                        {displayDataGeneral}
                                        {displayDataSuplimentarTitle}
                                        {displayDataSuplimentar}
                                        {displayDataNeprevazutTitle}
                                        {displayDataNeprevazut}
                                    </tbody>
                                </table>
                            </form>
                        
                            <div className='mb-3 mt-2 rounded customAddTask'>
                                <MDBox pt={1} pb={1} 
                                    variant="gradient"
                                    bgColor="primary"
                                    borderRadius="lg"
                                    coloredShadow="primary"
                                >
                                    <AddTask 
                                        date={date} 
                                        tabId={tabId}
                                        refreshData={refreshData}
                                        updateShowToast={setShowToast}
                                        updateMessageToast={setMessageToast}
                                        updateTypeToast={setTypeToast}
                                    />
                                </MDBox>
                            </div>
                        </div>

                        <div className='custom-TabNoteRow col-2 mt-4 px-1 mb-3'>
                            <ListNoteTab tabNoteTabId={tabId} tabNoteTabDate={date}/>
                        </div>
                    </div>
                </MDBox>
              </Card>
            </Grid>
            
          </Grid>
        </MDBox>
        {/* <ToastComp show={showToast} message={messageToast} type={typeToast}/> */}
      </DashboardLayout>
    )
}


export default TabDetails;