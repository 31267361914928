import React, { useEffect, useState } from "react";

import StatisticCustomCard from "components/CustomCard/StatisticCustomCard";

const BoxTabNote = (props) => {

    const [userData, setUserData] = useState('');
    const [messageData, setMessageData] = useState('');
    const [dateData, setDateData] = useState('');

    useEffect(() => {
        setUserData(props.user)
        setMessageData(props.message)
        setDateData(props.date)
    }, [props])

    return(
        <StatisticCustomCard
            topLeft={userData}
            topRight={ dateData}
            bottomLeft={messageData}
        />
    )
}

export default BoxTabNote