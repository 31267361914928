import React, { useState, useEffect } from "react";

import axios from 'axios';

import AddTabNote from "./AddTabNote";
import BoxTabNote from "./BoxTabNote";

import SimpleCustomCard from "../CustomCard/SimpleCustomCard";

const ListNoteTab = (props) => {

    const [displayNoteData, setDisplayNoteData] = useState([]);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        functSetListNoteData();
    }, [props])

    const functSetListNoteData = async () => {
        const resNoteTab = await axios.get(`/api/tabs/note-tab/${props.tabNoteTabId}/${props.tabNoteTabDate}`)
        .then((data) => {
            setDisplayNoteData(
                data.data.data.map((item) => {
                    return <BoxTabNote user={item.user_id} message={item.message} date={item.date} key={item.date} />
                })
            )
        });
    }

    if(loading) {
        functSetListNoteData();
        setLoading(false);
    }

    return(
        <SimpleCustomCard coloredShadow="primary"
            top={<AddTabNote tabNoteTabId={props.tabNoteTabId} tabNoteTabDate={props.tabNoteTabDate} setUpdateDisplayData={setLoading}/>}
            bottom={displayNoteData}
        />
    )

}

export default ListNoteTab