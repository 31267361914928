import React, { Component } from "react";
import axios from "axios";
import { format } from 'date-fns';

import Form from 'react-bootstrap/Form';
import CalendarComp from "components/CalendarComp/CalendarComp";
import MDButton from "components/MDButton";



class AddDailyNotification extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        validated: false,
        day_date: format(new Date(), "yyyy-MM-dd"),
        important: this.props.important ? this.props.important : false
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleClick = (e) => {
        this.updateImportantStatus(e.target.checked)
    }

    updateImportantStatus = (data) => {
        this.setState({important: data})
    }

    setSelectedDate = (data) => {
        this.setState({
            day_date: data
        })
    }

    setRefreshData = () => {
        
    }

    saveDailyNotifications = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;

        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({
            validated: true
        });

        const res = await axios.post('api/daily-notifications', this.state)
            .then(async (data) => {
                this.props.refreshData(true);
                this.props.updateShowModal(false);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);

                    this.setState({
                        errors: error.response.errors
                    })
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                };
            });
    }


    render() {
        return(
           
            <div className='card'>
                <div className="card-body">
                    <Form noValidate validated={this.state.validated} onSubmit={this.saveDailyNotifications} className="addDailyNotification">
                        <Form.Group className="input-group">
                            <div className="form-floating">
                                <Form.Control
                                    required
                                    name="message_notification"
                                    type="textarea"
                                    placeholder="Mesaj de notificare"
                                    value={this.state.message_notifications}
                                    onChange={this.handleInput}
                                />
                                <Form.Label htmlFor="addTaskName">Mesaj de notificare</Form.Label>

                                <Form.Control.Feedback type="invalid">Adauga un mesaj</Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <CalendarComp setSelectedDate={this.setSelectedDate} inputName={"day_date"} taskDate={this.state.day_date} setRefreshData={this.setRefreshData}/>
                        
                        <Form.Group className="input-group">
                            <div className="form-floating">
                                <Form.Control
                                    required
                                    name="order"
                                    type="number"
                                    placeholder="Ordine afisare"
                                    value={this.state.order}
                                    onChange={this.handleInput}
                                />
                                <Form.Label htmlFor="addTaskName">Ordine afisare</Form.Label>

                                <Form.Control.Feedback type="invalid">Adauga o prioritate</Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <Form.Group className="input-group">
                            <Form.Check 
                                name="important"
                                placeholder="Important"
                                checked={this.state.important}
                                onChange={this.handleClick}
                            />
                            <Form.Label htmlFor="important"> Important </Form.Label>
                        </Form.Group>

                        <div className="form-group mt-4 mb-1 text-end">
                            <MDButton variant="gradient" color="primary" type="submit" className="custom-primaryButton">Salveaza</MDButton>
                        </div>
                    </Form>
                </div>
            </div>

        )
    };
}

export default AddDailyNotification;