import React, { useEffect, useState } from "react";

import axios from 'axios';

import Form from 'react-bootstrap/Form';

import MDButton from "components/MDButton";

const AddTabNote = (props) => {

    const userId = localStorage.getItem('auth_id');
    const [tabNoteTabId, setTabNoteTabId] = useState(props.tabNoteTabId);
    const [tabNoteTabDate, setTabNoteTabDate] = useState(props.tabNoteTabDate);
    const [message, setMessage] = useState('');

    //for form
    const[validateForm, setValidatedForm] = useState(false)
    const[errorFormMessage, setErrorFormMessage] = useState([])

    useEffect(() => {
        setTabNoteTabId(props.tabNoteTabId)
        setTabNoteTabDate(props.tabNoteTabDate)
    }, [props.noteTabTabId, props.tabNoteTabDate]);


    const saveNoteTab = async (e) => {
        e.preventDefault();
  
        const form = e.currentTarget;
  
        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }
  
        setValidatedForm(true);
  
        const noteTabsData = {
          tab_id:  tabNoteTabId,
          day_date: tabNoteTabDate,
          user_id: userId,
          message: message
        }
  
        const res = await axios.post('api/tabs/note-tab', noteTabsData)
            .then(async (data) => {
  
                setMessage('');
                setErrorFormMessage([])
                props.setUpdateDisplayData(true)
                
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
  
                    setErrorFormMessage(error.response.data.errors);
  
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                };
            });
    }

    return(
        <>
        <Form noValidate id="addTabNote" onSubmit={saveNoteTab} >
            <Form.Group className="form-group">
                    <Form.Control
                        name="tab_id"
                        type="hidden"
                        value={tabNoteTabId}
                    />
            </Form.Group>
            <Form.Group className="form-group">
                    <Form.Control
                        name="user_id"
                        type="hidden"
                        value={userId}
                    />
            </Form.Group>
            <Form.Group className="form-group">
                    <Form.Control
                        name="day_date"
                        type="hidden"
                        value={tabNoteTabDate}
                    />
            </Form.Group>

            <Form.Group className="input-group">
                <div className="form-floating">
                    <Form.Control
                        required
                        name="message"
                        type="text"
                        placeholder="Adauga nota"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        isInvalid={errorFormMessage.message}
                    />
                    <Form.Label htmlFor="program">Nota</Form.Label>

                    <Form.Control.Feedback type="invalid">{errorFormMessage.message}</Form.Control.Feedback>
                </div>
            </Form.Group>

            <div className="form-group mt-4 mb-1">
                <MDButton variant="gradient" color="primary" type="submit" className="custom-primaryButton btn btn-primary btn-sm w-100">+ Adauga</MDButton>
            </div>
        </Form>
        </>
    )
}

export default AddTabNote;