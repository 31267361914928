import React, { useState } from "react";

import axios from "axios";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const EditInformationCategories = (props) => {


    const [name, setName] = useState(props.name ? props.name : '');
    const [description, setDescription] = useState(props.description ? props.description : '');
    const [order, setOrder] = useState(props.order ? props.order : 1);

    const [errorFormMessage, setErrorFormMessage] = useState('')
    const [validatedForm, setValidatedForm] = useState(false)



    const editInformationCategories = async () => {
  
        setValidatedForm(true);

        const dataIcForUpdate = {
            name: name,
            description: description,
            order: order
        }
  
        const res = await axios.put(`api/information-categories/${props.id}`, dataIcForUpdate)
            .then(() => {
                props.updateEditId('')
                props.setLoadingData(true);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
  
                    setErrorFormMessage (error.response.errors);
  
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                };
            });
    }

    const cancelEdit = () => {
        props.updateEditId('')
    }


    return(
        
        <tr>

                <td>
                    <Form.Group className="form-group">
                            <Form.Control
                                required
                                name="name"
                                type="text"
                                placeholder="Nume categorie"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">Adauga un nume</Form.Control.Feedback>
                    </Form.Group>
                </td>

                <td>
                    <Form.Group className="form-group">
                            <Form.Control
                                name="description"
                                type="text"
                                placeholder="Descriere"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                    </Form.Group> 
                </td>

                <td>
                    <Form.Group className="form-group">
                            <Form.Control
                                required
                                name="order"
                                type="number"
                                placeholder="Ordine afisare"
                                value={order}
                                onChange={(e) => setOrder(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">Adauga un numar</Form.Control.Feedback>
                    </Form.Group>
                </td>

                <td>
                    <div className="form-group mb-1">
                    <button type="submit" onClick={() => {editInformationCategories()}} className="btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check2-square" viewBox="0 0 16 16">
<path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
<path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg>
                        </button>
                        <button onClick={() => {cancelEdit()}} className="btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-x-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
                        </button>
                    </div>
                </td>
        </tr>
        

    )
}

export default EditInformationCategories