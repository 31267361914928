import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import axios from 'axios';

import HeaderPageComp from 'components/HeaderPageComp/HeaderPageComp';
import ToastComp from 'components/ToastComp/ToastComp';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

class MyAccount extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        id: '',
        name: '',
        email: '',
        old_password: '',
        password: '',
        confirmPassword: '',
        errorFormMessage: '',
        showToast: false,
        messageToast: '',
        typeToast: ''
    }

    async componentDidMount() {
        var userId = localStorage.getItem('auth_id');

        const res = await axios.get(`/api/users/${userId}`);

        this.setState({
            name: res.data.data[0].name,
            email: res.data.data[0].email
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    updateUserResetPassword = async (e) => {
        e.preventDefault();

        var updatePasswordData = {
            old_password: this.state.old_password,
            password: this.state.password,
            password_confirmation: this.state.confirmPassword
        }

        const res = await axios.post(`/api/user-reset-password`, updatePasswordData)
        .then( (response) => {

            let toastData = {
                'show': true,
                'message': "Parola a fost modificata",
                'type': 'success'
            }

            this.setState({
                errorFormMessage: '',
                old_password: '',
                password: '',
                confirmPassword: '',
                showToast: toastData.show,
                messageToast: toastData.message,
                typeToast: toastData.type
            })
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);

                this.setState({
                    errorFormMessage: error.response.data.errors
                })
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            };
        });
    }


    render(){
        return( 
        
        <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="primary"
                >
                  <MDTypography variant="h3" color="white">
                  <HeaderPageComp 
                        left={
                            <Link to={`/`} className='btn'> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fillRule="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                </svg> 
                            </Link>
                        } 
                        center={
                            <span className='tabTitle-custom'> Date utilizator </span>
                        }
                    />
                    </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                    <div className="card mt-5 shadow mx-5 text-center">
                        <div className="card-body">
                            <div>Nume: <b>{this.state.name}</b></div>
                            <div>Email: <b>{this.state.email}</b></div>
                        </div>
                    </div>
                </MDBox>

                <MDBox pt={3}>
                <div className="card my-5 shadow mx-5">
                            <div className="card-body">
                            <Form onSubmit={this.updateUserResetPassword} noValidate id="passwordResetLoogedUser">
                                <table className='table table-borderless mb-0'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Form.Group className="form-group mt-4 mb-1" controlId="old_password">
                                                    <Form.Control
                                                        required
                                                        name="old_password"
                                                        type="password"
                                                        placeholder="Parola veche"
                                                        value={this.state.old_password}
                                                        onChange={this.handleInput}
                                                        isInvalid={this.state.errorFormMessage.old_password}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{this.state.errorFormMessage.old_password}</Form.Control.Feedback>
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group className="form-group mt-4 mb-1" controlId="password">
                                                    <Form.Control
                                                        required
                                                        name="password"
                                                        type="password"
                                                        placeholder="Parola noua"
                                                        value={this.state.password}
                                                        onChange={this.handleInput}
                                                        isInvalid={this.state.errorFormMessage.password}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{this.state.errorFormMessage.password}</Form.Control.Feedback>
                                                </Form.Group>
                                            </td>
                                    

                                            <td>
                                                <Form.Group className="form-group mt-4 mb-1" controlId="confirmPassword">
                                                    <Form.Control
                                                        required
                                                        name="confirmPassword"
                                                        type="password"
                                                        placeholder="Confirmare parola"
                                                        value={this.state.confirmPassword}
                                                        onChange={this.handleInput}
                                                        isInvalid={this.state.errorFormMessage.confirmPassword}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{this.state.errorFormMessage.confirmPassword}</Form.Control.Feedback>
                                                </Form.Group>
                                            </td>

                                            <td>
                                            <MDButton variant="gradient" color="primary" type="submit" className="form-group mt-4 mb-1 custom-primaryButton">Reseteaza parola</MDButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Form>
                            </div>
                        </div>
                </MDBox>
              </Card>
            </Grid>
            
          </Grid>
        </MDBox>
        <ToastComp show={this.state.showToast} message={this.state.messageToast} type={this.state.typeToast}/>
      </DashboardLayout>
      )
        
        
        // <div className='container'>
        //             <div className='row mt-3 mb-5 pb-5'>
        //                 <HeaderPageComp 
        //                 left={
        //                     <Link to={`/`} className='btn'> 
        //                         <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fillRule="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
        //                             <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
        //                         </svg> 
        //                     </Link>
        //                 } 
        //                 center={
        //                     <span className='tabTitle-custom'> Date utilizator </span>
        //                 }
        //             />

        //                 <div className="card mt-5 shadow">
        //                     <div className="card-body">
        //                         <div>Nume: <b>{this.state.name}</b></div>
        //                         <div>Email: <b>{this.state.email}</b></div>
        //                     </div>
        //                 </div>

        //                 <div className="card mt-5 shadow">
        //                     <div className="card-body">
        //                     <Form onSubmit={this.updateUserResetPassword} noValidate id="passwordResetLoogedUser">
        //                         <table className='table table-borderless mb-0'>
        //                             <tbody>
        //                                 <tr>
        //                                     <td>
        //                                         <Form.Group className="form-group mt-4 mb-1" controlId="old_password">
        //                                             <Form.Control
        //                                                 required
        //                                                 name="old_password"
        //                                                 type="password"
        //                                                 placeholder="Parola veche"
        //                                                 value={this.state.old_password}
        //                                                 onChange={this.handleInput}
        //                                                 isInvalid={this.state.errorFormMessage.old_password}
        //                                             />
        //                                             <Form.Control.Feedback type="invalid">{this.state.errorFormMessage.old_password}</Form.Control.Feedback>
        //                                         </Form.Group>
        //                                     </td>
        //                                     <td>
        //                                         <Form.Group className="form-group mt-4 mb-1" controlId="password">
        //                                             <Form.Control
        //                                                 required
        //                                                 name="password"
        //                                                 type="password"
        //                                                 placeholder="Parola noua"
        //                                                 value={this.state.password}
        //                                                 onChange={this.handleInput}
        //                                                 isInvalid={this.state.errorFormMessage.password}
        //                                             />
        //                                             <Form.Control.Feedback type="invalid">{this.state.errorFormMessage.password}</Form.Control.Feedback>
        //                                         </Form.Group>
        //                                     </td>
                                    

        //                                     <td>
        //                                         <Form.Group className="form-group mt-4 mb-1" controlId="confirmPassword">
        //                                             <Form.Control
        //                                                 required
        //                                                 name="confirmPassword"
        //                                                 type="password"
        //                                                 placeholder="Confirmare parola"
        //                                                 value={this.state.confirmPassword}
        //                                                 onChange={this.handleInput}
        //                                                 isInvalid={this.state.errorFormMessage.confirmPassword}
        //                                             />
        //                                             <Form.Control.Feedback type="invalid">{this.state.errorFormMessage.confirmPassword}</Form.Control.Feedback>
        //                                         </Form.Group>
        //                                     </td>

        //                                     <td>
        //                                         <Button type="submit" className="form-group mt-4 mb-1 custom-primaryButton">Reseteaza parola</Button>
        //                                     </td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>
        //                     </Form>
        //                     </div>
        //                 </div>
                       
        //             </div>
        //             <ToastComp show={this.state.showToast} message={this.state.messageToast} type={this.state.typeToast}/>
        //         </div>
    }

}

export default MyAccount