import React, { Component } from "react";
import axios from "axios";

import Form from 'react-bootstrap/Form';



class EditTask extends Component {

    constructor(props) {
        super(props)
    }


    tab_id = this.props.tab_id;
    task_id = this.props.task_id;
    key = "key"+this.task_id;


    state = {
        'project': '',
        'task': '',
        'emergency': '',
        'priority': '',
        'estimate_time': '',
        'time_achieved': '',
        'observation': '',
        'day_date': '',
        'completed': '',
        'task_type_id': '',
        'tab_id': this.tab_id,
        'validated': false,
        'priority_types': ''
    }


    async componentDidMount(){

        const res = await axios.get(`/api/tasks/${this.task_id}`);

        if(res) {
            this.setState({
                'project': res.data.data.project,
                'task': res.data.data.task,
                'emergency': res.data.data.emergency,
                'priority': res.data.data.priority,
                'estimate_time': res.data.data.estimate_time,
                'time_achieved': res.data.data.time_achieved ? res.data.data.time_achieved: '',
                'observation': res.data.data.observation ? res.data.data.observation: '',
                'day_date': res.data.data.day_date,
                'completed': res.data.data.completed,
                'task_type_id': res.data.data.task_type_id,
                'tab_id': this.tab_id,
                'validated': this.props.validated
            })
        }

        this.getPriorityType();
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleClick = (e) => {
        this.updateCompletedStatus(e.target.checked)
    }

    updateCompletedStatus = (data) => {
        this.setState({completed: data})
    }

    updateTask = () => {
        this.props.dataForUpdate(this.state);
    }

    cancelEdit = () => {
        this.props.cancelEdit('');
        this.props.refreshData(true)
    }

    getPriorityType = async () => {
        const resPriorityTypes = await axios.get('/api/priority-type')
       
        if(resPriorityTypes.data.data.length > 0) {
            this.setState({
                priority_types: resPriorityTypes.data.data
            })
        }
        
    }


    render() {

        var dropdownEmergencyPriorityTypeDisplay = '';
        if (this.state.priority_types.length > 0) {
            dropdownEmergencyPriorityTypeDisplay = 
           
                this.state.priority_types.map((element) => {
                   return <option key={element.id + element.value} value={element.id} selected={element.id == this.state.emergency}>{element.name}</option>
                });
            
        }

        var dropdownImportantPriorityTypeDisplay = '';
        if (this.state.priority_types.length > 0) {
            dropdownImportantPriorityTypeDisplay = 
           
                this.state.priority_types.map((element) => {
                   return <option key={element.id + element.value} value={element.id} selected={element.id == this.state.priority}>{element.name}</option>
                });
            
        }


        return(
            <tr key={this.key}>
                <td>
                    <Form.Check 
                            name="completed"
                            placeholder="Proiect"
                            checked={this.state.completed}
                            onChange={this.handleClick}
                    />
                </td>
                <td>
                    <Form.Group className="form-group" controlId="addTaskProject">
                            <Form.Control
                                required
                                name="project"
                                type="text"
                                placeholder="Proiect"
                                value={this.state.project}
                                onChange={this.handleInput}
                            />
                            <Form.Control.Feedback type="invalid">Completeaza proiectul</Form.Control.Feedback>
                    </Form.Group>
                </td>
                <td>
                    <Form.Group className="form-group" controlId="addTaskName">
                            <Form.Control
                                required
                                name="task"
                                type="text"
                                placeholder="Task"
                                value={this.state.task}
                                onChange={this.handleInput}
                            />
                            <Form.Control.Feedback type="invalid">Completeaza task-ul</Form.Control.Feedback>
                    </Form.Group>
                </td>
                <td className="customMinDropdown">
                    <Form.Group className="form-group" controlId="addTaskEmergency">
                        <Form.Select name="emergency" onChange={this.handleInput}>
                                {dropdownEmergencyPriorityTypeDisplay}
                        </Form.Select>
                    </Form.Group>
                </td>
                <td className="customMinDropdown">
                    <Form.Group className="form-group" controlId="addTaskPriority">
                        <Form.Select name="priority" onChange={this.handleInput}>
                                {dropdownImportantPriorityTypeDisplay}
                        </Form.Select>
                    </Form.Group>
                </td>
                <td>
                    <Form.Group className="form-group" controlId="addTaskEstimate">
                            <Form.Control
                                required
                                name="estimate_time"
                                type="number"
                                placeholder="Timp estimat"
                                value={this.state.estimate_time}
                                onChange={this.handleInput}
                            />
                            <Form.Control.Feedback type="invalid">Completeaza timpul estimat</Form.Control.Feedback>
                    </Form.Group>
                </td>
                <td>
                    <Form.Group className="form-group" controlId="addTaskAchieved">
                            <Form.Control
                                name="time_achieved"
                                type="number"
                                placeholder="Timp realizat"
                                value={this.state.time_achieved}
                                onChange={this.handleInput}
                            />
                    </Form.Group>
                </td>

                <td>
                    <Form.Group className="form-group" controlId="addTaskObservation">
                            <Form.Control
                                name="observation"
                                type="text"
                                placeholder="Observatii"
                                value={this.state.observation}
                                onChange={this.handleInput}
                            />
                    </Form.Group>
                </td>


                <td>
                    <div className="form-group d-flex">
                        <button type="submit" onClick={() => {this.updateTask()}} className="btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-check2-square" viewBox="0 0 16 16">
<path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
<path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg>
                        </button>
                        <button onClick={() => {this.cancelEdit()}} className="btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-x-circle" viewBox="0 0 16 16">
<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
                        </button>
                    </div>
                </td>
        
            </tr>
                                
        )
    };
}

export default EditTask;