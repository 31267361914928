import React, { useEffect, useState } from 'react';
import axios from 'axios';

import ModalComp from '../ModalComp/ModalComp';
import AddProgramFormComp from './AddProgramFormComp';
import EditProgramFormComp from './EditProgramFormComp';

const ProgramComp = (props) => {
  const [displayData, setDisplayData] = useState('');
  
  const [onlyInfo, setOnlyInfo] = useState(props.onlyInfo ? props.onlyInfo : false)

  const [showModal, setShowModal] = useState(false);

  useEffect( () => {
    setProgramDataDisplay()
  }, [props]);

  const updateShowModal = (data) => {
    setShowModal(data);
  }

  const updateDisplayData = (data) => {
    setDisplayData(data);
    setProgramDataDisplay()
  }


  const setProgramDataDisplay = async () => {
    const resProgram = await axios.get(`/api/tabs/work-program/${props.programTabId}/${props.programTabDate}`);

    if(onlyInfo) {
      setDisplayData('Program: necompletat')

      if (resProgram.data.data.length != 0 && resProgram.data.data[0].program) {
        setDisplayData('Program: '+resProgram.data.data[0].program)
      }
    } else {
      if (resProgram.data.data.length != 0 && resProgram.data.data[0].program) {
        setDisplayData(
          <>
            <div className='d-flex justify-content-center align-items-center px-2'>({resProgram.data.data[0].program})</div>
            <ModalComp 
              idComp="modalEditProgram"
              variantButton="gradient"
              colorButton="dark"
              propsClassName="btn btn-sm"
              buttonName={
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>
              } 
              modalTitle="Modifica program" 
              idLabelComp="modalEditProgramLabel"
              toDisplayComp={
                <EditProgramFormComp programTabId={props.programTabId} programTabDate={props.programTabDate} updateShowModal={updateShowModal} updateDisplayData={updateDisplayData}/>
              }
              updateShowModal={updateShowModal}
              modalUpdateShow={showModal}
          />
          </>
        )
      } else {
        setDisplayData(
          <ModalComp 
              idComp="modalAddProgram"
              variantButton="gradient"
              colorButton="dark"
              propsClassName="btn btn-sm"
              buttonName="+ Adauga program" 
              modalTitle="Adauga program" 
              idLabelComp="modalAddProgramLabel"
              toDisplayComp={
                  <AddProgramFormComp programTabId={props.programTabId} programTabDate={props.programTabDate} updateShowModal={updateShowModal} updateDisplayData={updateDisplayData}/>
              }
              updateShowModal={updateShowModal}
              modalUpdateShow={showModal}
          />
        )
      }
    }
  }

  return (
      <div className='d-flex justify-content-center'>{displayData}</div>
  );
}

export default ProgramComp;