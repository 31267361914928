import React, { useState } from 'react';
import {Link} from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";


const ForgotPassword = (props) => {
    
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);
    const [requestMessage, setRequestMessage] = useState('');
    const [classMessage, setClassMessage] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setLoadingButton(true);

        const forgotPasswordData = {
            email: email,
        }

        axios.get('/sanctum/csrf-cookie').then(res => {
            axios.post('/api/forgot-password', forgotPasswordData)
            .then( response => {
                setRequestMessage(response.data.status)
                setClassMessage('btn btn-success')
                setErrorMessage([])
                setLoadingButton(false);
            })
            .catch((data) => {
                if(data.response.data.errors){
                    setErrorMessage(data.response.data.errors)
                } else {
                    setRequestMessage(data.response.data.message)
                    setClassMessage('btn btn-danger')
                }

                setLoadingButton(false);
            })
        })
    }

    return(
        <CoverLayout image={bgImage}>
        <Card>
            <MDBox
            variant="gradient"
            bgColor="primary"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
            >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Reseteaza parola
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
                
            </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
            <MDBox component="div" role="div">
                    <Form onSubmit={handleFormSubmit} noValidate id="forgotPassword">
                        {requestMessage ? <span className={classMessage}>{requestMessage}</span>: ''}

                        <Form.Group className="form-group mt-4 mb-1" controlId="email">
                            <Form.Control
                                required
                                name="email"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                isInvalid={errorMessage.email}
                            />
                            <Form.Control.Feedback type="invalid">{errorMessage.email}</Form.Control.Feedback>
                        </Form.Group>

                        <div className='d-flex justify-content-center align-item-center'>
                        {loadingButton 
                        ? 
                        <MDButton variant="gradient" color="disabled" className="form-group mt-4 mb-1 text-customPrimary" disabled>
                            <div className="spinner-border spinner-border-md text-customPrimary" role="status"><span className="sr-only text-customPrimary"></span></div>
                        </MDButton>
                        :
                        <MDButton variant="gradient" color="primary" type="submit" className="form-group mt-4 mb-1 text-customPrimary">Trimite mail</MDButton>}
                        </div>
                    </Form>
                <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                        <Link to={`/login`} className='btn mt-2'> Login </Link>
                </MDTypography>
                </MDBox>
            </MDBox>
            </MDBox>
        </Card>
    </CoverLayout>        
    );
}

export default ForgotPassword;