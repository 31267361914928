import React, { Component } from "react";
import axios from "axios";

import MDButton from "components/MDButton";
import Form from 'react-bootstrap/Form';

import './css/ToggleSwitch.css';


class EditTab extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        tab_id: this.props.tab_id,
        name: '',
        active: Boolean(false),
        validated: false
    }


    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    changeToggleValue = () => {
        this.setState({
            'active': !this.state.active
        })
    }

    async componentDidMount(){

        const res = await axios.get(`/api/tabs/${this.state.tab_id}`);

        if(res) {
            this.setState({
                'name': res.data.data.name,
                'active': res.data.data.active
            })
        }
    }

    updateTab = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({
            validated: true
        });

        const res = await axios.put(`/api/tabs/${this.state.tab_id}`, this.state)
        .then( async (data) => {
            const res2 = await axios.get('/api/tabs');
            this.props.refreshData(res2.data.data);

            let toastData = {
                'show': true,
                'message': "Tab-ul a fost modificat",
                'type': 'success'
            }
            this.props.updateShowModal(false);
            this.props.updateShowToast(toastData.show);
            this.props.updateMessageToast(toastData.message);
            this.props.updateTypeToast(toastData.type);
        })
        .catch(function (error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);

                this.setState({
                    errors: error.response.errors
                })
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            };
        });
    }

    


    render() {
        return(
           
                        <div className='card'>
                            <div className="card-body">
                            <Form noValidate validated={this.state.validated} onSubmit={this.updateTab} >
                                <Form.Group className="input-group" >
                                    <div className="form-floating">
                                        <Form.Control
                                            required
                                            name="name"
                                            type="text"
                                            placeholder="Nume"
                                            value={this.state.name}
                                            onChange={this.handleInput}
                                        />
                                        <Form.Label htmlFor="addTaskName">Name</Form.Label>

                                        <Form.Control.Feedback type="invalid">Adauga un nume</Form.Control.Feedback>
                                    </div>
                                </Form.Group>

                                <div className="form-group mt-4 mb-1 text-end">
                                    <MDButton type="submit" variant="gradient" color="primary">Modifica</MDButton>
                                </div>
                            </Form>

                                
                            </div>
                        </div>
        )
    };
}

export default EditTab;