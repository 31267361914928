
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { format } from 'date-fns';
import swal from 'sweetalert';
import ModalComp from 'components/ModalComp/ModalComp';
import ToastComp from 'components/ToastComp/ToastComp';
import ProgramComp from 'components/ProgramComp/ProgramComp';
import TimeWorkComp from 'components/TimeWorkComp/TimeWorkComp';

import MDButton from "components/MDButton";

// @mui material components
import Icon from "@mui/material/Icon";

import AddTab from './components/AddTab';
import EditTab from './components/EditTab';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";


class Tabs extends Component {

    constructor(props) {
        super(props)
    }

    date = format(new Date(), "yyyy-MM-dd");

    state = {
        tabs: [],
        loading: true,
        showModal: '',
        showToast: false,
        messageToast: '',
        typeToast: ''
    }


    async componentDidMount(){

        const res = await axios.get('/api/tabs');
   
        if(res.data.data.length > 0){
            this.setState({
                tabs: res.data.data,
                loading: false,
            }) 
        } else if(res.data.data.length === 0){
            this.setState({
                loading: false
            }) 
        }

    }

    async componentDidUpdate(prevProps, prevState) {

        const res = await axios.get('/api/tabs');
   
        if(prevState.tabs.length !== res.data.data.length){
            this.setState({
                tabs: res.data.data,
                showToast: false,
            })
            if(res.data.data.length > 0) {
                this.setState({
                    loading: false,
                }) 
            }
        }
    }

    refreshData = (data) => {
        this.setState({
            tabs: data
        })
    }

    updateShowModal = (data) => {
        this.setState({
            showModal: data
        })
    }

    updateShowToast = (data) => {
        this.setState({
            showToast: data
        })
    }

    updateMessageToast = (data) => {
        this.setState({
            messageToast: data
        })
    }

    updateTypeToast = (data) => {
        this.setState({
            typeToast: data
        })
    }

    deleteTab = async (e, tabId) => {
        
        const idClicked = e.currentTarget; 
        const prevButtonDelete = idClicked.innerHTML;
        idClicked.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="sr-only"></span></div>';

        swal({
            title: "Stergi todo-ul?",
            text: "Odata cu todo-ul vor fi sterse si task-urile aferente.",
            icon: "warning",
            buttons: {
                cancel: "Anuleaza",
                Sterge: true
            },
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                const deleteTab = await axios.delete(`/api/tabs/${tabId}`);

                if(deleteTab) {
                    const res = await axios.get('/api/tabs');
                    this.setState({
                        tabs: res.data.data 
                    })
                } else {
                    console.log(deleteTab.data.message);
                }
            } else {
                idClicked.innerHTML = prevButtonDelete;
            }
          });    
    }

    render() {

        var displayData = '';
        var editIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" fill="currentColor"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" fill="currentColor"/></svg>;

        if (this.state.loading && this.state.tabs.length > 0) {
            displayData = <h2>Loading...</h2>;
        } else  if (!this.state.loading && this.state.tabs.length == 0) {
            displayData = <h3>Nu exista todo-uri. Adauga unul nou apasand butonul de mai sus.</h3>;
        } else {
            displayData = this.state.tabs.map((item) => {
                return(
                    
                        <Grid item xs={12} md={6} lg={3} key={item.id}>
                            <MDBox mb={1.5}>
                            {/* <Link to={`tab-details/${item.id}`} key={item.id} className='d-block p-2'> */}
                            <ComplexStatisticsCard
                                color="primary"
                                icon="person"
                                title={<ProgramComp programTabId={item.id} programTabDate={this.date} onlyInfo={true}/>}
                                count={
                                    <Link to={`tab-details/${item.id}`} key={item.id} className='d-flex justify-content-between align-items-center p-2 text-decoration-none text-reset'>
                                       <Icon>visibility</Icon>  {item.name} 
                                    </Link>
                                }
                                percentage={{
                                color: "success",
                                amount: "",
                                label: <TimeWorkComp tabId={item.id}/>,
                                }}
                                actionEdit={
                                    <ModalComp 
                                        idComp="modalEditTab"
                                        variantButton="contained"
                                        colorButton="white"
                                        buttonName={<><Icon>edit</Icon>&nbsp;edit</>}
                                        modalTitle="Editeaza todo" 
                                        idLabelComp="modalAddTabLabel"
                                        toDisplayComp={
                                            <EditTab
                                                tab_id={item.id} 
                                                refreshData={this.refreshData}
                                                updateShowModal={this.updateShowModal}
                                                updateShowToast={this.updateShowToast}
                                                updateMessageToast={this.updateMessageToast}
                                                updateTypeToast={this.updateTypeToast}
                                            />
                                        }
                                        updateShowModal={this.updateShowModal}
                                        modalUpdateShow={this.state.showModal}
                                    />
                                }
                                actionDelete={
                                    <MDButton onClick={ (e) => this.deleteTab(e, item.id)}>
                                        <Icon>delete</Icon>&nbsp;delete
                                    </MDButton>
                                }
                            />
                            {/* </Link> */}
                            </MDBox>
                        </Grid>
                    
                )
            });

        }

         
        return(
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox textAlign="center" className="mt-4">
                    <ModalComp 
                        idComp="modalAddTab"
                        variantButton="gradient"
                        colorButton="dark"
                        buttonName="+ Adauga ToDo" 
                        modalTitle="Adauga todo" 
                        idLabelComp="modalAddTabLabel"
                        toDisplayComp={
                            <AddTab 
                                refreshData={this.refreshData}
                                updateShowModal={this.updateShowModal} 
                                updateShowToast={this.updateShowToast}
                                updateMessageToast={this.updateMessageToast}
                                updateTypeToast={this.updateTypeToast}
                            
                            />}
                        updateShowModal={this.updateShowModal}
                        modalUpdateShow={this.state.showModal}
                    />
                </MDBox>


                <MDBox py={3}>
                    <Grid container spacing={3}>
                         {displayData}
                    </Grid>
                </MDBox>

                {/* <ToastComp show={this.state.showToast} message={this.state.messageToast} type={this.state.typeToast}/> */}
            </DashboardLayout>
        )
    };
};

export default Tabs;