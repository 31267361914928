import React, { useState } from "react";
import axios from "axios";

import Form from 'react-bootstrap/Form';
import MDButton from "components/MDButton";


const EditUser = (props) => {

    const userId = props.userId;

    const [loading, setLoading] = useState(true)
    const [validated, setValidated] = useState(false)
    const [errors, setErrors] = useState([])

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')


    const setEditData = async () => {
        const res = await axios.get(`/api/users/${userId}`)
        .then((data) => {
            setName(data.data.data[0].name)
            setEmail(data.data.data[0].email)
            localStorage.setItem('auth_name', data.data.data[0].name)
        })
    }

    const saveNewDataUser = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;

        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true)

        const saveNewUserData = {
            name: name,
            email: email
        }

        const res = await axios.put(`api/users/${userId}`, saveNewUserData)
            .then(() => {
                props.setShowModal(false);
                const res = axios.get(`/api/users`)
                .then((data) => {
                    props.setUserList(data.data.data)
                })
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);

                    setErrors(error.response.errors)

                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                };
            });
    }

    if(loading) {
        setEditData()
        setLoading(false)
    }

    return(
        
        <div className='card'>
            <div className="card-body">
                        <div className="form-floating text-center mb-4">
                           <b>{email}</b>
                        </div>
                <Form noValidate validated={validated} onSubmit={saveNewDataUser} className="addDailyNotification">
                    <Form.Group className="input-group">
                        <div className="form-floating">
                            <Form.Control
                                required
                                name="name"
                                type="textarea"
                                placeholder="Nume"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Form.Label htmlFor="addTaskName">Nume</Form.Label>

                            <Form.Control.Feedback type="invalid">Adauga un nume</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <div className="form-group mt-4 mb-1 text-end">
                        <MDButton variat="gradient" color="primary" type="submit" className="custom-primaryButton">Salveaza</MDButton>
                    </div>
                </Form>
            </div>
        </div>

    )
}


export default EditUser;