import React, { useState, useEffect } from 'react';
import MDButton from "components/MDButton";
import Modal from 'react-bootstrap/Modal';


const ModalComp = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  useEffect(() => {
    if(props.modalUpdateShow !== '') {
      setShow(props.modalUpdateShow);
      props.updateShowModal('');
    }
  }, [props.modalUpdateShow]);


  return (
    <>
      <MDButton variant={props.variantButton} color={props.colorButton} onClick={handleShow}>
        {props.buttonName}
      </MDButton>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="bg-primaryColor">
          <Modal.Title>{props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body> {props.toDisplayComp} </Modal.Body>
       
      </Modal>
    </>
  );
}

export default ModalComp;