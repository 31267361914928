import React, { useEffect, useState } from "react";

import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import MDButton from "components/MDButton";

const AddProgramFormComp = (props) => {

    const [programTabId, setProgramTabId] = useState('');
    const [programTabDate, setProgramTabDate] = useState('');
    const [program, setProgram] = useState('');

    //for form
    const[validateForm, setValidatedForm] = useState(false)
    const[errorFormMessage, setErrorFormMessage] = useState('')

    useEffect(() => {
        setProgramTabId(props.programTabId);
        setProgramTabDate(props.programTabDate)
    }, [props.programTabId]);


    const saveProgram = async (e) => {
        e.preventDefault();
  
        const form = e.currentTarget;
  
        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }
  
        setValidatedForm(true);
  
        const programReqestData = {
          tab_id:  props.programTabId,
          day_date: props.programTabDate,
          program: program.toString()
        }
  
        const res = await axios.post('api/tabs/work-program', programReqestData)
            .then(async (data) => {
  
                props.updateShowModal(false);
                props.updateDisplayData('(' + programReqestData.program + ')')
                
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
  
                    setErrorFormMessage (error.response.errors);
  
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                };
            });
    }

    return(
        <>
        <Form noValidate validated={validateForm} onSubmit={saveProgram} >
            <Form.Group className="form-group">
                    <Form.Control
                        name="tab_id"
                        type="hidden"
                        value={programTabId}
                    />
            </Form.Group>

            <Form.Group className="form-group">
                    <Form.Control
                        name="day_date"
                        type="hidden"
                        value={programTabDate}
                    />
            </Form.Group>

            <Form.Group className="input-group">
                <div className="form-floating">
                    <Form.Control
                        required
                        name="program"
                        type="text"
                        placeholder="Adauga program"
                        defaultValue={program}
                        onChange={(e) => setProgram(e.target.value)}
                    />
                    <Form.Label htmlFor="program">Program</Form.Label>

                    <Form.Control.Feedback type="invalid">Campul nu poate fi gol</Form.Control.Feedback>
                </div>
            </Form.Group>

            <div className="form-group mt-4 mb-1 text-end">
                <MDButton variant="gradient" color="primary" type="submit" className="custom-primaryButton">Salveaza</MDButton>
            </div>
        </Form>
        </>
    )
}

export default AddProgramFormComp;