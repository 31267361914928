import React, { useState } from 'react';
import ToastComp from 'components/ToastComp/ToastComp';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

import MDButton from "components/MDButton";


const UserRegister = (props) => {
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const registerData = {
            name: name,
            email: email,
            password: password,
            password_confirmation: confirmPassword
        }

        axios.get('/sanctum/csrf-cookie').then(res => {
            axios.post('/api/register', registerData)
            .then(response => {
                props.setShowModal(false);
                const res = axios.get(`/api/users`)
                .then((data) => {
                    props.setUserList(data.data.data)
                })

                let toastData = {
                    'show': true,
                    'message': "Utilizatorul " + name + " a fost adaugat",
                    'type': 'success'
                }
                
                props.setShowToast(toastData.show);
                props.setMessageToast(toastData.message);
                props.setTypeToast(toastData.type);

            })
            .catch((data) => {
                setErrorMessage(data.response.data.errors)    
            })
        })
    }


    return(
        <div className="card">
            <div className="card-body">
                <Form onSubmit={handleFormSubmit} noValidate id="userRegister">
                    <Form.Group className="form-group mt-2 mb-1" controlId="name">
                        <Form.Control
                            required
                            name="name"
                            type="text"
                            placeholder="Nume"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            isInvalid={errorMessage.name}
                        />
                        <Form.Control.Feedback type="invalid">{errorMessage.name}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group mt-2 mb-1" controlId="email">
                        <Form.Control
                            required
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={errorMessage.email}
                        />
                        <Form.Control.Feedback type="invalid">{errorMessage.email}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group mt-2 mb-1" controlId="password">
                        <Form.Control
                            required
                            name="user"
                            type="password"
                            placeholder="Parola"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isInvalid={errorMessage.password}
                        />
                        <Form.Control.Feedback type="invalid">{errorMessage.password}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-group mt-2 mb-1" controlId="confirmPassword">
                        <Form.Control
                            required
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirmare parola"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            isInvalid={errorMessage.confirmPassword}
                        />
                        <Form.Control.Feedback type="invalid">{errorMessage.confirmPassword}</Form.Control.Feedback>
                    </Form.Group>

                    <div className="form-group mt-2 mb-1 text-end">
                    <MDButton variat="gradient" color="primary" type="submit" className="form-group mt-4 mb-1 custom-primaryButton text-right">Adauga utilizator</MDButton>
                    </div>
                    
                </Form>
            </div>
            <ToastComp show={showToast} message={messageToast} type={typeToast}/>
        </div>
        
    );
}

export default UserRegister;