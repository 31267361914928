import React, { Component } from "react";
import axios from "axios";

import MDButton from "components/MDButton";
import Form from 'react-bootstrap/Form';



class AddTab extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        name: '',
        validated: false,
        errors: ''
    }

    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    saveTab = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;

        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({
            validated: true
        });

        const res = await axios.post('api/tabs', this.state)
            .then(async (data) => {

                const res2 = await axios.get('/api/tabs');
                this.props.refreshData(res2.data.data);

                this.props.updateShowModal(false);

                let tabName = data.data.data.name;

                let toastData = {
                    'show': true,
                    'message': "Tab-ul " + tabName + " a fost creat",
                    'type': 'success'
                }
                
           
                this.props.updateShowToast(toastData.show);
                this.props.updateMessageToast(toastData.message);
                this.props.updateTypeToast(toastData.type);
                
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);

                    this.setState({
                        errors: error.response.errors
                    })
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                };
            });
    }


    render() {
        return(
           
            <div className='card'>
                <div className="card-body">
                    <Form noValidate validated={this.state.validated} onSubmit={this.saveTab} >
                        <Form.Group className="input-group">
                            <div className="form-floating">
                                <Form.Control
                                    required
                                    name="name"
                                    type="text"
                                    placeholder="Nume"
                                    value={this.state.name}
                                    onChange={this.handleInput}
                                />
                                <Form.Label htmlFor="addTaskName">Nume</Form.Label>

                                <Form.Control.Feedback type="invalid">Adauga un nume</Form.Control.Feedback>
                            </div>
                        </Form.Group>

                        <div className="form-group mt-4 mb-1 text-end">
                            <MDButton variant="gradient" color="primary" type="submit">Salveaza</MDButton>
                        </div>
                    </Form>
                </div>
            </div>

        )
    };
}

export default AddTab;