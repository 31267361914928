// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function StatisticCustomCard({ topLeft, topRight, bottomLeft, bottomRight }) {
  return (
    <Card sx={{mt:"5px", mb:"5px"}}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color="primary"
          >
            {topLeft}
          </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" px={2}>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {topRight}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={1} px={2}>
        <MDTypography component="p" variant="button" color="text" display="flex">
          {bottomLeft}
        </MDTypography>
        <MDBox textAlign="right">
          <MDTypography component="span" variant="button" fontWeight="light" color="text">
            {bottomRight}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}


export default StatisticCustomCard;
