import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { format } from 'date-fns';

import Form from 'react-bootstrap/Form';
import CalendarComp from "components/CalendarComp/CalendarComp";
import MDButton from "components/MDButton";


const EditDailyNotification = (props) => {

    const dailyNotificaionId = props.dailyNotificationId;

    const [loading, setLoading] = useState(true)
    const [validated, setValidated] = useState(false)
    const [errors, setErrors] = useState([])

    const [messageNotification, setMessageNotification] = useState('')
    const [dayDate, setDayDate] = useState('')
    const [order, setOrder] = useState('')
    const [important, setImportant] = useState('')
    const [refreshData, setRefreshData] = useState(false)


    const setEditData = async () => {
        const res = await axios.get(`/api/daily-notifications/${dailyNotificaionId}`)
        .then((data) => {
            setMessageNotification(data.data.data[0].message_notification)
            setDayDate(data.data.data[0].day_date)
            setOrder(data.data.data[0].order)
            setImportant(data.data.data[0].important)
        })
    }

    const saveDailyNotifications = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;

        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }

        setValidated(true)

        const saveDailyNotificationsData = {
            message_notification: messageNotification,
            day_date: dayDate,
            order: order,
            important: important
        }

        const res = await axios.put(`api/daily-notifications/${dailyNotificaionId}`, saveDailyNotificationsData)
            .then(() => {
                props.updateShowModal(false);
                props.loadingData(true);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);

                    setErrors(error.response.errors)

                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                };
            });
    }

    if(loading) {
        setEditData()
        setLoading(false)
    }

    return(
        
        <div className='card'>
            <div className="card-body">
                <Form noValidate validated={validated} onSubmit={saveDailyNotifications} className="addDailyNotification">
                    <Form.Group className="input-group">
                        <div className="form-floating">
                            <Form.Control
                                required
                                name="message_notification"
                                type="textarea"
                                placeholder="Mesaj de notificare"
                                value={messageNotification}
                                onChange={(e) => setMessageNotification(e.target.value)}
                            />
                            <Form.Label htmlFor="addTaskName">Mesaj de notificare</Form.Label>

                            <Form.Control.Feedback type="invalid">Adauga un mesaj</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <CalendarComp setSelectedDate={setDayDate} inputName={"day_date"} taskDate={dayDate} setRefreshData={setRefreshData}/>
                    
                    <Form.Group className="input-group">
                        <div className="form-floating">
                            <Form.Control
                                required
                                name="order"
                                type="number"
                                placeholder="Ordine afisare"
                                value={order}
                                onChange={(e) => setOrder(e.target.value)}
                            />
                            <Form.Label htmlFor="addTaskName">Ordine afisare</Form.Label>

                            <Form.Control.Feedback type="invalid">Adauga o prioritate</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group className="input-group">
                        <Form.Check 
                            name="important"
                            placeholder="Important"
                            checked={important}
                            onChange={(e) => setImportant(e.target.checked)}
                        />
                        <Form.Label htmlFor="important"> Important </Form.Label>
                    </Form.Group>

                    <div className="form-group mt-4 mb-1 text-end">
                        <MDButton variant="gradient" color="primary" type="submit" className="custom-primaryButton">Salveaza</MDButton>
                    </div>
                </Form>
            </div>
        </div>

    )
}


export default EditDailyNotification;