import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';

import HeaderPageComp from 'components/HeaderPageComp/HeaderPageComp';
import ModalComp from 'components/ModalComp/ModalComp';
import AddDailyNotification from "./components/AddDailyNotification";
import EditDailyNotification from "./components/EditDailyNotification";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import style from "assets/css/style.css"

const DailyNotifications = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [loadingData, setLoadingData] = useState(true)

    const [headerListDailyNotifications, setHeaderListDailyNotifications] = useState(
        <tr className='customGeneral thead-dark'>
            <th>#</th>
            <th>Mesaj de notificare</th>
            <th>Data afisare</th>
            <th>Ordine afisare</th>
            <th>Important</th>
            <th>Actiuni</th>
        </tr>
    )
    const [bodyListDailyNotifications, setBodyListDailyNotifications] = useState([])

    const [showModalEdit, setShowModalEdit] = useState(false)
    const [contentModalEdit, setContentModalEdit] = useState(false)
    
    const handleClose = () => setShowModalEdit(false);

    const handleShowModalEdit = (itemId) => {
        setShowModalEdit(true)
        setContentModalEdit(
            <EditDailyNotification
                dailyNotificationId={itemId}
                updateShowModal={setShowModalEdit}
                loadingData={setLoadingData}
            />
        )
    }


    const getListDailyNotifications = async () => {
        const resAllListNotifications = await axios.get(`/api/daily-notifications`)
        .then((data) => {
            var dataBody = data.data.data.map((item, index) => {
                var importantValue = item.important ? 'Da': 'Nu';
                return (<tr key={item.id+'edit'}>
                            <td>{index + 1}</td>
                            <td><b>{item.message_notification}</b></td>
                            <td>{item.day_date}</td>
                            <td>{item.order}</td>
                            <td>{importantValue}</td>
                            <td>
                                <button variant="" onClick={() => handleShowModalEdit(item.id)} className="btn btn-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                </button>

                                <button type='button' className='btn' onClick={(event) => {deleteNotification(event, item.id)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" fill="currentColor"/>
                                </svg>
                                </button>
                            </td>
                        </tr>)
            })

            setBodyListDailyNotifications(dataBody)
        }) 
    }

    const deleteNotification = async (e, notificationId) => {
        
        const idClicked = e.currentTarget; 
        idClicked.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';

        const deleteNotification = await axios.delete(`/api/daily-notifications/${notificationId}`);
        if(deleteNotification) {
            getListDailyNotifications()
        } else {
            console.log(deleteNotification.data.message);
        }
    }

    if(loadingData){
        setShowModal(false)
        getListDailyNotifications()
        setLoadingData(false)
    }


    return(

        <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="primary"
                >
                  <MDTypography variant="h3" color="white">
                  <HeaderPageComp 
                    left={
                        <Link to={`/`} className='btn'> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fillRule="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                            </svg> 
                        </Link>
                    } 
                    center={
                        <div className='d-flex'>
                            <span className='tabTitle-custom'> Notificare zilnica </span>
                        </div>
                    }
                    right={
                        <ModalComp 
                            variantButton="gradient"
                            colorButton="dark"
                            buttonName="Adauga notificare"
                            propsClassName="custom-primaryButton btn btn-sm"
                            modalTitle="Adauga notificare"
                            idLabelComp="modalAddDailyNotificationLabel"
                            modalUpdateShow={showModal}
                            updateShowModal={setShowModal}
                            toDisplayComp={
                                <AddDailyNotification
                                    refreshData={setLoadingData}
                                    updateShowModal={setShowModal}
                                />
                            }
                        />
                    }
                />
                    </MDTypography>
                </MDBox>
                <MDBox pt={3}>

                    <div className='custom-TableRow col-12 p-2 px-3'>
                        <table className="table table-striped">
                            <thead>{headerListDailyNotifications}</thead>
                            <tbody>{bodyListDailyNotifications}</tbody>
                        </table>
                    </div>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        <Modal show={showModalEdit} onHide={handleClose} centered>
            <Modal.Header closeButton className="bg-primaryColor">
                <Modal.Title> Modifica notificarea </Modal.Title>
            </Modal.Header>
            <Modal.Body> {contentModalEdit} </Modal.Body>
        </Modal>
      </DashboardLayout>
    )

}

export default DailyNotifications