import React, { useState } from "react";

import axios from "axios";

import Form from 'react-bootstrap/Form';
import MDButton from "components/MDButton";

const AddInformationCategories = (props) => {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState(1);

    const [errorFormMessage, setErrorFormMessage] = useState('')
    const [validatedForm, setValidatedForm] = useState(false)


    const saveInformationCategories = async (e) => {
        e.preventDefault();
  
        const form = e.currentTarget;
  
        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }
  
        setValidatedForm(true);

        const requestData = {
            name: name,
            description: description,
            order: order,
        }
  
        const res = await axios.post('api/information-categories', requestData)
            .then(() => {
                props.updateShowModal(false);
                props.updateLoadingData(true);
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
  
                    setErrorFormMessage (error.response.errors);
  
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                };
            });
    }


    return(
        <div className='card'>
            <div className="card-body">
                <Form noValidate validated={validatedForm} onSubmit={saveInformationCategories} className="addInformationCategories">
                    <Form.Group className="input-group">
                        <div className="form-floating">
                            <Form.Control
                                required
                                name="name"
                                type="text"
                                placeholder="Nume categorie"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Form.Label htmlFor="addTaskName">Nume categorie</Form.Label>

                            <Form.Control.Feedback type="invalid">Adauga un nume</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group className="input-group">
                        <div className="form-floating">
                            <Form.Control
                                name="description"
                                type="text"
                                placeholder="Descriere"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <Form.Label htmlFor="addTaskName">Descriere</Form.Label>

                        </div>
                    </Form.Group>                    
                    
                    <Form.Group className="input-group">
                        <div className="form-floating">
                            <Form.Control
                                required
                                name="order"
                                type="number"
                                placeholder="Ordine afisare"
                                value={order}
                                onChange={(e) => setOrder(e.target.value)}
                            />
                            <Form.Label htmlFor="addTaskName">Ordine afisare</Form.Label>

                            <Form.Control.Feedback type="invalid">Adauga un numar</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <div className="form-group mt-4 mb-1 text-end">
                        <MDButton variant="gradient" color="primary" type="submit" className="custom-primaryButton">Salveaza</MDButton>
                    </div>
                </Form>
            </div>
        </div>

    )
}

export default AddInformationCategories