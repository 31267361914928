import React, {useEffect, useRef, useState} from "react";
import { Calendar } from 'react-date-range';
import format from "date-fns/format";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './CalendarComp.css'; // theme css file



const CalendarComp = (props) => {


    const formatDateToShow = (date) => {
        let newDate = date.split("-")[2]+"/"+date.split("-")[1]+"/"+date.split("-")[0];
        return newDate;
    }

    const [calendar, setCalendar] = useState('');

    const [open, setOpen] = useState(false);

    const handleSelect = (date) => {
        setCalendar(format(date, "yyyy-MM-dd"));
        props.setSelectedDate(format(date, "yyyy-MM-dd"));
        props.setRefreshData(true)
    }

    const refOne = useRef(null);


    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
        setCalendar(props.taskDate);
    }, [props.taskDate])

    const hideOnEscape = (e) => {
        if(e.key === "Escape") {
            setOpen(false);
        }
    }

    const hideOnClickOutside = (e) => {
        if(refOne.current){
            setOpen(false)
        }
    }


    return (
        <div className="calendarWrap">
            <input
                value={formatDateToShow(calendar)}
                name={props.inputName ? props.inputName : ''}
                readOnly 
                className="form-control"
                onClick={() => setOpen(open => !open)}
            />
            <div ref={refOne}>
            {open && 
                <Calendar 
                    date={new Date(calendar)} 
                    onChange={handleSelect} 
                    className="calendarElement"
                />
            }
            </div>
        </div>
    )
}

export default CalendarComp;