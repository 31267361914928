import { set } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const ToastComp = (props) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [typeClass, setTypeClass] = useState('');

  const toastMessage = (type) => {
      if(type === 'success') {
          setTypeMessage('Succes')
          setTypeClass('bg-success')
      } else {
        setTypeMessage('Eroare')
        setTypeClass('bg-danger')
      }
  }
  

  useEffect(() => {
      if(props.show) {
        setShow(props.show)
        setMessage(props.message)
        setType(props.type)

        toastMessage(props.type)
      }
  }, [props.show])

  return (
    <Row>
      <ToastContainer className="p-3" position="bottom-end">
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide className={typeClass} >
          <Toast.Header>
            <strong className="me-auto">{typeMessage}</strong>
          </Toast.Header>
          <Toast.Body> <span style={{color:'white'}}><b> {message} </b></span></Toast.Body>
        </Toast>
        </ToastContainer>
    </Row>
  );
}

export default ToastComp;