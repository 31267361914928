// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

function SimpleCustomCard({ top, bottom }) {
  return (
    <Card className={'shadow-lg'}>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
        {top}
      </MDBox>
      <MDBox p={3} >
        {bottom}
      </MDBox>
    </Card>
  );
}


// Typechecking props for the SimpleCustomCard
SimpleCustomCard.propTypes = {

};

export default SimpleCustomCard;
