import React, { useEffect, useState } from 'react'

import Stack from 'react-bootstrap/Stack';

const HeaderPageComp = (props) => {
    const [left, setLeft] = useState('');
    const [center, setCenter] = useState('');
    const [right, setRight] = useState('');

    useEffect(() => {
        setLeft(props.left)
        setCenter(props.center)
        setRight(props.right)
    }, [props])


    return(
        <div className='' style={{zIndex:1}}>
            <Stack direction="horizontal" gap={3}>
            <div className='d-flex justify-content-between w-100'>
                <div className='col-2 d-flex justify-content-start align-items-center'>
                    {left}
                </div>

                <div className='col-8 d-flex justify-content-center align-items-center'> 
                    {center}
                </div>

                <div className='col-2 d-flex justify-content-end align-items-center'>
                    {right} 
                </div>
                    
            </div>
            </Stack>
        </div>
    )
}

export default HeaderPageComp