import React, { Component } from "react";
import axios from "axios";

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import MDButton from "components/MDButton";



class AddTask extends Component {

    constructor(props) {
        super(props)
    }

    tabId = this.props.tabId;
    
    today = new Date();
    todayDate = this.today.getFullYear()+'-'+(this.today.getMonth()+1)+'-'+this.today.getDate();

    state = {
        'project': '',
        'task': '',
        'emergency': 1,
        'priority': 1,
        'estimate_time': '',
        'time_achieved': '',
        'observation': '',
        'day_date': this.props.date,
        'tab_id': this.tabId,
        'completed': false,
        'task_type_id': 1,
        'validated': false,
        'task_types': '',
        'priority_types': ''
    }


    componentDidMount(){

        this.setState({
            'day_date': this.props.date,
        });

        this.getTaskType();
        this.getPriorityType();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.date !== this.props.date) {
            this.setState({
                'day_date': this.props.date,
            });
        }
    }


    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    getTaskType = async () => {
        const resTaskTypes = await axios.get('/api/task-type')
       
        if(resTaskTypes.data.data.length > 0) {
            this.setState({
                task_types: resTaskTypes.data.data
            })
        }

        
    }

    getPriorityType = async () => {
        const resPriorityTypes = await axios.get('/api/priority-type')
       
        if(resPriorityTypes.data.data.length > 0) {
            this.setState({
                priority_types: resPriorityTypes.data.data
            })
        }

        
    }

    saveTask = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({
            validated: true
        });

        const res = await axios.post('/api/tasks', this.state);

        if(res.data.length) {

            let toastData = {
                'show': true,
                'message': "Task-ul " + this.state.task + " a fost creat",
                'type': 'success'
            }
            
            this.props.updateShowToast(toastData.show);
            this.props.updateMessageToast(toastData.message);
            this.props.updateTypeToast(toastData.type);

            this.setState({
                project: '',
                task: '',
                emergency: this.state.emergency,
                priority: this.state.priority,
                estimate_time: '',
                time_achieved: '',
                observation: '',
                task_type_id: this.state.task_type_id,
                validated: false
            });

            

            const res2 = await axios.get(`/api/tasks/tab/${this.state.tab_id}/${this.state.day_date}`);
            this.props.refreshData(res2.data.data);
        }
    }

    render() {

        var dropdownTaskTypeDisplay =  ''

        if (this.state.task_types.length > 0) {
            dropdownTaskTypeDisplay = 
           
                this.state.task_types.map((element) => {
                   return <option key={element.id + element.value} value={element.id}>{element.name}</option>
                });
            
        }

        var dropdownPriorityTypeDisplay =  ''

        if (this.state.priority_types.length > 0) {
            dropdownPriorityTypeDisplay = 
           
                this.state.priority_types.map((element) => {
                   return <option key={element.id + element.value} value={element.id}>{element.name}</option>
                });
            
        }

        return(
            <div>
                <Form noValidate validated={this.state.validated} onSubmit={this.saveTask} id="addTask">
                    <Form.Group className="form-group">
                        <Form.Control
                            name="tab_id"
                            type="hidden"
                            value={this.state.tab_id}
                        />
                    </Form.Group>
                    <table className='table table-borderless mb-0'>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Group className="form-group" controlId="addTaskProject">
                                        <Form.Label>Proiect</Form.Label>
                                        <Form.Control
                                            required
                                            name="project"
                                            type="text"
                                            placeholder="Proiect"
                                            value={this.state.project}
                                            onChange={this.handleInput}
                                        />
                                        <Form.Control.Feedback type="invalid">Completeaza proiectul</Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group className="form-group" controlId="addTaskName">
                                        <Form.Label>Task</Form.Label>
                                        <Form.Control
                                            required
                                            name="task"
                                            type="text"
                                            placeholder="Task"
                                            value={this.state.task}
                                            onChange={this.handleInput}
                                        />
                                        <Form.Control.Feedback type="invalid">Completeaza task-ul</Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td className="customMinDropdown">
                                    <Form.Group className="form-group" controlId="addTaskEmergency">
                                        <Form.Label>Urgenta</Form.Label>
                                        <Form.Select name="emergency" onChange={this.handleInput}>
                                             {dropdownPriorityTypeDisplay}
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td className="customMinDropdown">
                                    <Form.Group className="form-group" controlId="addTaskPriority">
                                        <Form.Label>Importanta</Form.Label>
                                        <Form.Select name="priority" onChange={this.handleInput}>
                                             {dropdownPriorityTypeDisplay}
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group className="form-group" controlId="addTaskEstimate">
                                        <Form.Label>Timp estimat</Form.Label>
                                        <Form.Control
                                            required
                                            name="estimate_time"
                                            type="number"
                                            placeholder="Timp estimat"
                                            value={this.state.estimate_time}
                                            onChange={this.handleInput}
                                        />
                                        <Form.Control.Feedback type="invalid">Completeaza timpul estimat (numar)</Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group className="form-group" controlId="addTaskAchieved">
                                        <Form.Label>Timp realizat</Form.Label>
                                        <Form.Control
                                            name="time_achieved"
                                            type="number"
                                            placeholder="Timp realizat"
                                            value={this.state.time_achieved}
                                            onChange={this.handleInput}
                                        />
                                    </Form.Group>
                                </td>

                                <td>
                                    <Form.Group className="form-group" controlId="addTaskObservation">
                                        <Form.Label>Observatii</Form.Label>
                                        <Form.Control
                                            name="observation"
                                            type="text"
                                            placeholder="Observatii"
                                            value={this.state.observation}
                                            onChange={this.handleInput}
                                        />
                                    </Form.Group>
                                </td>

                                <td>
                                    <Form.Group className="form-group" controlId="addTaskTypeTask">
                                        <Form.Label>Tip task</Form.Label>
                                        <Form.Select name="task_type_id" onChange={this.handleInput}>
                                             {dropdownTaskTypeDisplay}
                                        </Form.Select>
                                    </Form.Group>
                                </td>


                                <td className="form-action">
                                    <div className="form-group">
                                        <MDButton variant="gradient" color="dark" type="submit" className="">Adauga</MDButton>
                                    </div>
                                </td>
                        
                            </tr>
                        </tbody>
                    </table>
                    <Form.Group className="form-group">
                        <Form.Control
                            name="observation"
                            type="hidden"
                            value={this.props.date}
                        />
                    </Form.Group>
                </Form>


                   
            </div>

        )
    };
}

export default AddTask;