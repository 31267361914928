import React, { useEffect, useState } from "react";

import axios from 'axios';
import { format } from 'date-fns';

import Carousel from 'react-bootstrap/Carousel';

import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

const ShowDailyNotifications = () => {

    const [loading, setLoading] = useState(true)
    const [dailyNotifications, setDailyNotifications] = useState([]);
    const today = format(new Date(), "yyyy-MM-dd");

    const getDailyNotifications = async (today) => {
        const resDailyNotifications = await axios.get(`/api/daily-notifications-d/${today}`);
        setDailyNotifications(resDailyNotifications.data.data)
    }

    if(loading) {
        getDailyNotifications(today)
        setLoading(false)
    }

    if(dailyNotifications.length > 0) {
        return(
            <>
            <Icon color="success" fontSize="medium" sx={{mr: 2}}>notifications</Icon>
            <Carousel
                indicators={false}
                controls={false}
                interval={2500}
                className="daily-notifications"
            >
                {dailyNotifications.map((item) => {
                    var classElement = "daily-notification-text";
                    if(item.important == true) {
                        classElement = "daily-notification-text daily-notification-important-text"
                    }
                    return <Carousel.Item key={item.id}><span className={classElement}>{item.message_notification}</span></Carousel.Item>
                })}
            </Carousel>
            </>
            
        )
    } else {
        return(
            <><Icon color="disabled" fontSize="medium" sx={{mr: 2}}>notifications</Icon> Nu sunt notificari</>
        )
    }
}

export default ShowDailyNotifications