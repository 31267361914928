import React, { useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";


const ResetPassword = (props) => {
    
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorFormMessage, setErrorFormMessage] = useState([]);
    const [otherErrorMessage, setOtherErrorMessage] = useState('');
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const resetPasswordData = {
            token: token,
            email: email,
            password: password,
            password_confirmation: confirmPassword
        }

        axios.get('/sanctum/csrf-cookie').then(res => {
            axios.post('/api/reset-password', resetPasswordData)
            .then( () => {
                setRedirectToLogin(true);
            })
            .catch((data) => {
               
                if(data.response.data.errors){
                    setErrorFormMessage(data.response.data.errors)
                }
                setOtherErrorMessage(data.response.data.message)
            })
        })
    }

    if(redirectToLogin) {
        return <Navigate to="/login"></Navigate>
    }

    return(

        <CoverLayout image={bgImage}>
        <Card>
            <MDBox
            variant="gradient"
            bgColor="primary"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
            >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Reseteaza parola
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
                
            </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
            <MDBox component="div" role="div">
                <Form onSubmit={handleFormSubmit} noValidate id="passwordReset">
                                {otherErrorMessage ? <span className="btn btn-danger">{otherErrorMessage}</span>: ''}
                                <Form.Group className="form-group mt-4 mb-1" controlId="token">
                                    <Form.Control
                                        required
                                        name="token"
                                        type="hidden"
                                        value={token}
                                    />
                                </Form.Group>

                                <Form.Group className="form-group mt-4 mb-1" controlId="email">
                                    <Form.Control
                                        required
                                        name="email"
                                        type="text"
                                        value={email}
                                        readOnly
                                    />
                                </Form.Group>

                                <Form.Group className="form-group mt-4 mb-1" controlId="password">
                                    <Form.Control
                                        required
                                        name="user"
                                        type="password"
                                        placeholder="Parola"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        isInvalid={errorFormMessage.password}
                                    />
                                    <Form.Control.Feedback type="invalid">{errorFormMessage.password}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="form-group mt-4 mb-1" controlId="confirmPassword">
                                    <Form.Control
                                        required
                                        name="confirmPassword"
                                        type="password"
                                        placeholder="Confirmare parola"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        isInvalid={errorFormMessage.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">{errorFormMessage.confirmPassword}</Form.Control.Feedback>
                                </Form.Group>

                                <MDBox textAlign="center">
                                <MDButton variant="gradient" color="primary" type="submit" className="form-group mt-4 mb-1 custom-primaryButton">Reseteaza parola</MDButton>
                                </MDBox>
                            </Form>
                <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                        <Link to={`/login`} className='btn mt-2'> Login </Link>
                </MDTypography>
                </MDBox>
            </MDBox>
            </MDBox>
        </Card>
    </CoverLayout>
    );
}

export default ResetPassword;