import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import axios from 'axios'
import Form from 'react-bootstrap/Form';

import HeaderPageComp from 'components/HeaderPageComp/HeaderPageComp';
import ModalComp from 'components/ModalComp/ModalComp';
import AddInformationDetails from './components/AddInformationDetails';
import EditInformationDetails from './components/EditInformationDetails';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const InformationDetails = () => {

    let {infoCatId} = useParams();

    const [categoryName, setCategoryName] = useState('');

    const [headTable, setHeadTable] = useState(
                <tr className='customGeneral thead-dark'>
                    <th>Nume</th>
                    <th>Link</th>
                    <th>Observatie</th>
                    <th>Ordine</th>
                    <th>Actiuni</th>
                </tr>
            )
    const [bodyTable, setBodyTable] = useState([])
    const [loadingData, setLoadingData] = useState(true)
    const [editableRowId, setEditableRowId] = useState('')

    const [showModal, setShowModal] = useState(false)

    const [errorFormMessage, setErrorFormMessage] = useState('')
    const [validatedForm, setValidatedForm] = useState(true)

    const handleEditClick = (event, element) => {
        event.preventDefault();
        setEditableRowId(element.id)
        setLoadingData(true)
    }

    const updateEditId = (data) => {
        setEditableRowId(data)
        setLoadingData(true)
    }

    const getCategoryName = async () => {
        const resCategoryName = await axios.get(`api/information-categories/${infoCatId}`).
        then(data => {
            setCategoryName(data.data.data.name)
        })
    }

    const getIcData = async () => {
        const resInformationCategories = await axios.get(`/api/information-details/${infoCatId}`)
        .then((data) => {

            setBodyTable(data.data.data.map((item) => {
                if (editableRowId === item.id) {
                    return <EditInformationDetails 
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                link={item.link} 
                                observation={item.observation} 
                                order={item.order}
                                categoryId={item.category_id}
                                updateEditId={updateEditId}
                                setLoadingData={setLoadingData}
                            />
                }
                return <tr key={item.id}>
                   <td>{item.name}</td>
                   <td><a href={item.link} target="_blank">{item.link}</a></td> 
                   <td>{item.observation}</td> 
                   <td>{item.order}</td> 
                   <td>
                        <button onClick={(event) => handleEditClick(event, item)} className='btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </button>
                        <button type='button' className='btn' onClick={ (e) => deleteTask(e, item.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </td> 
                </tr>
            }))

            setLoadingData(false)
        });
    }

    

    const deleteTask = async (e, elementId) => {
        
        const idClicked = e.currentTarget;
        idClicked.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';

        const deleteElement = await axios.delete(`/api/information-categories/${elementId}`);
        if(deleteElement) {
           setLoadingData(true)
        } else {
            console.log(deleteTask.data.message);
        }
    }

    const updateShowModal = (data) => {
        setShowModal(data)
    }

    const updateLoadingData = (data) => {
        setLoadingData(data)
    }

    const editInformationCategories = (e) => {
        e.preventDefault();
  
        const form = e.currentTarget;
  
        if(form.checkValidity() === false){
            e.preventDefault();
            e.stopPropagation();
        }
    }

    if(loadingData) {
        getIcData()
        getCategoryName()
    }



    return(

        <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="primary"
                >
                  <MDTypography variant="h3" color="white">
                  <HeaderPageComp 
                    left={
                        <Link to={`/informations`} className='btn'> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fillRule="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                            </svg> 
                        </Link>
                    } 
                    center={
                        <div className='d-flex'>
                            <span className='tabTitle-custom'> {categoryName} </span>
                        </div>
                    }
                    right={
                        <ModalComp 
                            variantButton="gradient"
                            colorButton="dark"
                            buttonName="Adauga info"
                            propsClassName="custom-primaryButton btn btn-sm"
                            modalTitle="Adauga info"
                            idLabelComp="modalAddInformationDetailsLabel"
                            modalUpdateShow={showModal}
                            updateShowModal={updateShowModal}
                            toDisplayComp={
                                <AddInformationDetails
                                    updateLoadingData={updateLoadingData}
                                    updateShowModal={updateShowModal}
                                    catInfo={infoCatId}
                                />
                            }
                        />
                    }
                />
                    </MDTypography>
                </MDBox>
                <MDBox pt={3}>

                    <div className='custom-TableRow col-12 p-2 px-3'>
                        <Form noValidate validated={validatedForm} onSubmit={editInformationCategories} className="addInformationCategories">
                            <table className='table table-hover'>
                                <thead>
                                    {headTable}
                                </thead>

                                <tbody>
                                    {bodyTable}
                                </tbody>
                            </table>
                        </Form>
                    </div>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    )
}

export default InformationDetails