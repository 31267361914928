import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";


const Login = (props) => {
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const loginData = {
            email: email,
            password: password
        }

        axios.get('/sanctum/csrf-cookie').then(res => {
            axios.post('/api/login', loginData)
            .then(response => {
                localStorage.setItem('auth_id', response.data.data.user.id)
                localStorage.setItem('auth_name', response.data.data.user.name)
                localStorage.setItem('auth_token', response.data.data.token)
                props.updateToken(response.data.data.token)
                props.updateName(response.data.data.user.name)
            })
            .catch((data) => {
                setErrorMessage(data.response.data.errors)    
            })
        })
    }


    return(
        <CoverLayout image={bgImage}>
            <Card>
                <MDBox
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
                >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Intra in cont
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                    
                </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                   
                        <Form onSubmit={handleFormSubmit} noValidate id="login">
                            <Form.Group className="form-group mt-4 mb-1" controlId="email">
                                <Form.Control
                                    required
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    isInvalid={errorMessage.email}
                                />
                                <Form.Control.Feedback type="invalid">{errorMessage.email}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="form-group mt-4 mb-1" controlId="password">
                                <Form.Control
                                    required
                                    name="user"
                                    type="password"
                                    placeholder="Parola"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    isInvalid={errorMessage.password}
                                />
                                <Form.Control.Feedback type="invalid">{errorMessage.password}</Form.Control.Feedback>
                            </Form.Group>

                            <MDBox textAlign="center">
                            <MDButton variant="gradient" color="primary" type="submit" className="form-group mt-4 mb-1 custom-primaryButton">Login</MDButton>
                            </MDBox>
                        </Form>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                <Link to={`/forgot-password`} className='btn mt-2'> Am uitat parola</Link>
                            </MDTypography>
                        </MDBox>

                </MDBox>
            </Card>
        </CoverLayout>        
    );
}

export default Login;