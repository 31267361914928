
import React, { useState } from "react";

import axios from "axios";
import { format } from 'date-fns';

const TimeWorkComp = (props) => {
    const [loading, setLoading] = useState(true)
    const [timeEstimate, setTimeEstimate] = useState(0)
    const [timeAchieved, setTimeAchieved] = useState(0)
    const today = format(new Date(), "yyyy-MM-dd");

    const getData = async () => {
       const res = await axios.get(`/api/tasks/tab/${props.tabId}/${today}`)
        .then((data) => {
            var totalEstimate = timeEstimate; 
            var totalAchieved = timeAchieved;

            if(data.data.data.length) {
                data.data.data.map((item) => {

                    
                    if ( item.estimate_time === null) {
                        item.estimate_time = 0
                    }

                    totalEstimate = totalEstimate + parseFloat(item.estimate_time);
                    setTimeEstimate(totalEstimate)

                    if (item.time_achieved === null) {
                        item.time_achieved = 0
                    }

                    totalAchieved = totalAchieved + parseFloat(item.time_achieved);
                    setTimeAchieved(totalAchieved)
                    
                })
            }
        })
    }


    if(loading) {
        getData()
        setLoading(false)
    }

    return (
        <div>T.Estimat: {parseFloat(timeEstimate).toFixed(2)} / T.Realizat: {parseFloat(timeAchieved).toFixed(2)}</div>
    )
}

export default TimeWorkComp