import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

import EditUser from './components/EditUser';
import UserRegister from './components/UserRegister';
import HeaderPageComp from 'components/HeaderPageComp/HeaderPageComp';
import ToastComp from 'components/ToastComp/ToastComp';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import style from "assets/css/style.css"

class Users extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        loading: true,
        usersList: [],
        showToast: false,
        messageToast: '',
        typeToast: '',
        showModal: false,
        contentModalEdit: '',
    }

    async componentDidMount(){

        const res = await axios.get(`/api/users`);

        this.setState({
            usersList: res.data.data
        })
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.usersList.length !== this.state.usersList.length) {
            const res = await axios.get(`/api/users`);

            if(res.data.data){
                this.setState({
                    usersList: res.data.data
                })
            }
            
        }
    }

    updateShowToast = (data) => {
        this.setState({
            showToast: data
        })
    }

    updateMessageToast = (data) => {
        this.setState({
            messageToast: data
        })
    }

    updateTypeToast = (data) => {
        this.setState({
            typeToast: data
        })
    }

    setShowModal = (data) => {
        this.setState({
            showModal: data
        })
    }

    addUser = (event) => {
        event.preventDefault();
        this.setState({
            showModal: true,
            contentModalEdit: <UserRegister 
                                setShowModal={this.setShowModal} 
                                setUserList={this.setUserList} 
                                setShowToast={this.updateShowToast}
                                setMessageToast={this.updateMessageToast}
                                setTypeToast={this.updateTypeToast}
                            />
        })
    }

    editUser = (event, userId) => {
        event.preventDefault();
        this.setState({
            showModal: true,
            contentModalEdit: <EditUser userId={userId} setShowModal={this.setShowModal} setUserList={this.setUserList}/>
        })
    }

    setUserList = (data) => {
        this.setState({
            usersList: data
        })
    }

    handleClose = () => this.setState({showModal: false});

    deleteUser = async (e, userId) => {
        
        const idClicked = e.currentTarget; 
        idClicked.innerHTML = '<div class="spinner-border spinner-border-sm" role="status"><span class="sr-only"></span></div>';

        const deleteUser = await axios.delete(`/api/users/${userId}`);

        if(deleteUser) {
            const resNewUsersList = await axios.get(`/api/users`);
            this.setState({
                usersList: resNewUsersList.data.data
            })

            this.setState({
                showToast: true,
                messageToast: deleteUser.data,
                typeToast: 'success'
            })
        } else {
            console.log(deleteUser.data.message);
        }
    }

    render() {
        var displayDataUsers = [];
        if(this.state.usersList.length > 0) {
            displayDataUsers = this.state.usersList.map((item) => {
                
                if(item.id == localStorage.getItem('auth_id')) {
                    return <tr key={item.id}>
                                <td>(eu) <b>{item.name}</b></td>
                                <td>{item.email}</td>
                                <td>
                                    <button type='button' className='btn custom-link' onClick={(event) => this.editUser(event, item.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                        </svg>
                                    </button>
                                    <button type='button' className='btn custom-button-not-allowed'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" fill="currentColor"/>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                }
                return <tr key={item.id}>
                            <td><b>{item.name}</b></td>
                            <td>{item.email}</td>
                            <td>
                                <button type='button' className='btn custom-link' onClick={(event) => this.editUser(event, item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                    </svg>
                                </button>

                                <button type='button' className='btn custom-link' onClick={ (e) => this.deleteUser(e, item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fillRule="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" fill="currentColor"/>
                                    </svg>
                                </button>
                            </td>
                        </tr>
            });
        }
        return(
            <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="primary"
                >
                  <MDTypography variant="h3" color="white">
                        <HeaderPageComp 
                            left={
                                <Link to={`/`} className='btn'> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fillRule="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                                    </svg> 
                                </Link>
                            } 
                            center={
                                <span className='tabTitle-custom'> Lista utilizatori </span>
                            }
                            right={
                                <>
                                <MDButton variant="gradient" color="dark" type='button' className='btn custom-primaryButton' onClick={(event) => this.addUser(event)}>
                                   + Adauga utilizator
                                </MDButton>
                                </>
                            }
                        />
                    </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                    <div className='custom-TableRow col-12 p-2 px-3'>
                        <table className='table table-hover'>
                            <thead>
                                <tr className='customGeneral thead-dark'>
                                    <th><b>Nume</b></th>
                                    <th><b>Email</b></th>
                                    <th><b>Actiune</b></th>
                                </tr>
                            </thead>
                            <tbody>
                            {displayDataUsers}
                            </tbody>
                        </table>
                    </div>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        <Modal show={this.state.showModal} onHide={this.handleClose} centered>
            <Modal.Header closeButton className="bg-primaryColor">
                <Modal.Title> Modifica user </Modal.Title>
            </Modal.Header>
            <Modal.Body> {this.state.contentModalEdit} </Modal.Body>
        </Modal>

        <ToastComp show={this.state.showToast} message={this.state.messageToast} type={this.state.typeToast}/>
      </DashboardLayout>
        )
    }

}

export default Users